@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html,
body {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0px;
}

body {
  display: flex;
  flex-direction: column;
}

.header {
  max-width: 500px;
  padding-inline: 2rem;
}

.header > img {
  display: block;
  margin: auto;
  max-width: 100%;
}

.header > h2 {
  font-family: "TWK Everett", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 144%;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}

footer {
  width: 100%;
  padding: 1.5rem 2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
